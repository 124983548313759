div.image-list-header-bar
    opacity: 100%
    background: none

div
    &.image-list-header-bar
        opacity: 100%
        &.pdf
            button
                color: grey
            

